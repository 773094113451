@use "sass:math";
@use '~@THC/styles/breakpoints';

@use '~@THC/styles/color';

@use '~@THC/styles/core';

$black: rgb(0 0 0);
$border-style: solid core.$border-width color.$dark_grey_2;

.videoContainer {
	display: flex;
	height: 100%;
	padding: core.$space4 0;
	width: 100%;

	&.center {
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.videoAR {
		background-color: $black;
		border-bottom: $border-style;
		border-top: $border-style;
		overflow: hidden;
		pointer-events: all;
		position: relative;
		width: 100%;

		@include breakpoints.minTablet {
			border: $border-style;
			border-radius: core.$space2;
			max-width: min(86%, 1400px);
			overflow: hidden;
		}

		&::before {
			content: '';
			float: left;
			height: 0;
			margin-left: -1px;
			padding-top: 56.25%; // 16:9 aspect ratio
			width: 1px;
		}

		&::after {
			clear: both;
			content: '';
			display: table;
		}
	}

	iframe {
		display: block;
		height: 100%;
		position: relative;
		width: 100%;
		z-index: 1;
	}

	[data-thc-icon] {
		--thc-icon-size: #{core.$space * 12};

		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);

		path {
			@media (prefers-reduced-motion: no-preference) {
				animation: pulse 1s infinite ease-in-out;
			}
		}
	}
}

.drawer {
	[aria-modal] {
		background-color: transparent;
		pointer-events: none;
	}

	[data-drawer-backdrop] {
		background-color: rgba(color.$primary-black, 0.95);
	}

	[data-drawer-top-bar] {
		pointer-events: all;
		position: absolute;
		z-index: 1;
	}

	[data-drawer-content] {
		height: 100%;
		padding: 0;
	}
}

@keyframes pulse {
	0% {
		fill: #{color.$dark-grey-1};
	}

	50% {
		fill: #{color.$dark-grey-2};
	}

	100% {
		fill: #{color.$dark-grey-1};
	}
}
