@use "sass:math";
@use '~@THC/styles/core' as core;

@use '~@THC/styles/typography';

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

@import '/utilities/styles/common.scss';

.makesCarousel {
	max-width: 100vw; // Prevent storybook rendering wider than the viewport.
	overflow: hidden;
	text-align: center;
	text-wrap: pretty;
	padding-bottom: core.$space-64;
	padding-top: core.$space-64;

	h3 {
		@include typography.heading2();

		padding: 0 core.$space-24;
		margin: 0 auto core.$space-24;
		max-width: 30ch;

		strong {
			font-weight: inherit;
			color: var(--thc-color-primary-yellow);
		}
	}

	.tabletRows,
	.desktopRows {
		display: none;
	}

	ul {
		display: flex;
		justify-content: center;
		overflow: visible;

		& > li {
			padding: core.$space * 2 core.$space * 1.5 0;
		}
	}

	@media (max-width: ($mqBreakpointTablet - 0.02px)) {
		ul > li > *::before {
			height: $marginGeneric * 11;
			width: $marginGeneric * 11;
		}
	}

	@media (min-width: $mqBreakpointTablet) {
		padding-bottom: core.$space-80;

		.mobileRows {
			display: none;
		}

		.tabletRows {
			display: block;
		}

		h3 {
			@include typography.heading1-base();
			margin-bottom: core.$space-56;
		}

		ul > li {
			padding: core.$space core.$space * 4 0;
		}
	}

	@media (min-width: $breakpointContent) {
		.tabletRows {
			display: none;
		}

		.desktopRows {
			display: block;
		}

		ul {
			flex-wrap: nowrap;
		}
	}
}
