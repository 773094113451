/* stylelint-disable custom-property-pattern */
/* stylelint-disable property-no-vendor-prefix */

@use '~@motorway/mw-highway-code/src/styles/_core' as core;

@use '~@THC/styles/typography';

@use 'sass:math';

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

$mqBreakpointMobileSmall: 320px;
$mqBreakpointMobile: 480px;
$mqBreakpointTablet: 768px;
$mqBreakpointDesktop: 1200px;
$border-size: 2px;

$containerPaddingSmall: $marginGeneric * 3;

$fadedGrey: rgba($dark_grey_1, 0.28);
$default-font-color: $white;

@mixin tag {
	@include typography.small();

	@include centered;

	border-radius: $borderRadiusGeneric;
	height: $marginGeneric * 3;
	margin: 0;
	padding: 2px 12px;

	&:empty {
		display: none;
	}
}

@mixin largeTag {
	@include typography.heading6();

	font-weight: normal;
	height: $marginGeneric * 4;
	margin: 0;
}

$motorwayProMinWidth: $marginGeneric * 40; // 320px

$fluidTransition: cubic-bezier(0.25, 0.8, 0.25, 1);
$signInModalBreakpoint: $marginGeneric * 70; // Sign in modal needs custom breakpoint to make smooth transition betewen mobile and desktop

$mainColumnWidth: $marginGeneric * 64; // 512

$naturalBlackWithTransparency: rgba($natural_black, 0.32);

$marginGenericHalf: math.div($marginGeneric, 2);

$containerPaddingSmall: $marginGeneric * 3; // 24
$containerPaddingRegular: $marginGeneric * 5; // 40

// Use on main content container to add motorway correct spacing between header and footer

@mixin motorway-container-spacing() {
	padding: $marginGeneric * 12 $marginGeneric * 3 $marginGeneric * 20; // 96px 32px 160px

	@media (max-width: $breakpointTablet) {
		padding: $marginGeneric * 6 $marginGeneric * 3 $marginGeneric * 10; // 48px 32px 80px
	}
}

// Use for the main title of the page

@mixin motorway-main-title() {
	@include typography.heading2();

	margin-bottom: $marginGeneric * 4;
	text-align: center;

	@media (max-width: $breakpointMobile) {
		@include typography.heading3-base();

		margin-bottom: $marginGeneric * 3;
	}
}

// Use when you need narrow container for the content

@mixin motorway-dealer-narrow-container() {
	left: 0;
	margin: auto;
	max-width: $marginGeneric * 50; // 400
	right: 0;

	& p:last-child {
		padding-bottom: 0;
	}
}

@mixin mw-focus() {
	border-color: $white;
	box-shadow:
		0 0 0 2px $dark_grey_1,
		0 0 0 4px rgba(255 255 255 / 25%);

	& ~ .mw-input-icon {
		color: $white;
	}
}

@mixin mw-hover() {
	border-color: $white;

	& ~ .mw-input-icon {
		color: $white;
	}
}

@mixin mw-active() {
	border-color: $white;
}

@mixin generateMarginClasses($index) {
	@if ($index <= 20) {
		.mw-mt-#{$index} {
			margin-top: $marginGeneric * $index;
		}

		.mw-mb-#{$index} {
			margin-bottom: $marginGeneric * $index;
		}

		/* Here we increment our loop */

		@include generateMarginClasses($index + 1);
	}
}

@mixin dimensions($size) {
	height: $size;
	width: $size;
}

@mixin flex($grow, $shrink, $basis) {
	flex-basis: $basis;
	/* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
	flex-grow: $grow;
	/* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
	flex-shrink: $shrink;
}

@mixin AppleSafeArea($value: $marginGeneric * 3, $property: padding-bottom, $env: safe-area-inset-bottom) {
	#{$property}: $value;
	#{$property}: calc(#{$value} + env(#{$env}));
}

@mixin user-select() {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

@mixin disabled() {
	cursor: default;
	pointer-events: none;
}

@mixin singleRowTwoColumnGrid() {
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(1, 1fr);
}

$fakeToolbarLeft: calc((-100vw + var(--scrollBarWidth)) / 2 + 50%);
$fakeToolbarWidth: calc(100vw - var(--scrollBarWidth));

@mixin warningTriangle($size, $mobileSize, $fill, $stroke) {
	@include dimensions($size);

	@media (max-width: $breakpointTablet) {
		@include dimensions($mobileSize);
	}

	path {
		&:first-of-type {
			fill: $fill;
		}

		&:last-of-type {
			fill: $stroke;
		}
	}
}

@function calculate-padding($columnWidth, $marginWidth) {
	@return calc(((100vw - #{$columnWidth}) / 2) + #{$marginWidth});
}

@mixin siteWideHeaderHeight($property: height, $multiplier: 1) {
	#{$property}: $marginGeneric * 8 * $multiplier;

	@media (min-width: ($breakpointTablet + 1)) {
		#{$property}: $marginGeneric * 10 * $multiplier;
	}

	@media (min-width: ($breakpointContent + 1)) {
		#{$property}: $marginGeneric * 12 * $multiplier;
	}
}

@mixin generateIE11GridCells($index, $factor) {
	@if ($index < 12) {
		$classId: $index + 1;

		.photo#{$classId} {
			-ms-grid-column: mod($index, $factor) + 1;
			-ms-grid-column-span: 1;
			-ms-grid-row: floor((math.div($index, $factor)) + 1);
			-ms-grid-row-span: 1;
		}

		@include generateIE11GridCells($index + 1, $factor);
	}
}

.loadingSpinner {
	align-items: center;
	display: flex;
}

@mixin redButton() {
	background-color: $red;

	&:hover {
		background-color: $red_hover;
	}
}

@mixin blur() {
	box-shadow:
		inset -#{core.$space1} core.$space1 core.$space2 0 $dark_grey_1,
		inset core.$space1 -#{core.$space1} core.$space2 0 $dark_grey_1;
}

@mixin resetButtonStyles() {
	background: none;
	border: none;
	cursor: pointer;
}

@mixin defaultNavStyles() {
	align-items: center;
	display: flex;
	justify-content: flex-end;
	width: 100%;

	ul {
		align-items: center;
		display: grid;
		grid-auto-flow: column;
		height: var(--mw-dwa-header-height-desktop);

		li {
			margin: 0 core.$space4;
		}
	}

	div.menuButton {
		height: core.$space6;
		width: core.$space6;
	}
}
