@use "sass:math";
@use 'sass:color' as sassColor;

@use '~@THC/styles/core';

@use '~@THC/styles/modifiers';

@use '~@THC/styles/color';

@use '~@THC/styles/typography';

@use '~@THC/styles/breakpoints';

/* stylelint-disable function-no-unknown */
/* stylelint-disable function-name-case */

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

@import '/utilities/styles/common.scss';

$bgColor: sassColor.change(color.$primary-white, $alpha: 0.05);

.testimonialsWrapper {
	background-color: $natural_black;
	overflow: hidden;

	.testimonials {
		overflow-x: visible;
		padding-bottom: $marginGeneric * 6;
		padding-top: $marginGeneric * 6;
		position: relative;

		@media (min-width: $mqBreakpointTablet) {
			padding-bottom: $marginGeneric * 10;
			padding-top: $marginGeneric * 10;
		}
	}

	h2 {
		@include typography.heading2();

		border-radius: $borderRadiusGeneric;
		display: inline-block;
		margin-bottom: $marginGeneric * 6;
		padding-left: $marginGeneric;
		padding-right: $marginGeneric;
		position: relative;
		text-align: center;
		z-index: 2;
	}

	.carouselWrapper {
		margin-left: 0;
		margin-right: 0;
		position: relative;
		z-index: 2;
	}

	.carousel {
		padding-bottom: $marginGeneric * 6;
	}

	.carouselItem {
		align-items: center;
		background-color: $dark_grey_1;
		border-radius: $borderRadiusGeneric;
		display: flex;
		flex-direction: column;
		height: auto;
		margin-right: $marginGeneric * 2;
		margin-top: $marginGeneric * 4.5; // buyer logo
		padding: $marginGeneric * 8.25 $marginGeneric * 3 $marginGeneric * 6;
		position: relative;
		width: 100%;

		&:last-child {
			margin-right: 0;
		}

		& > span {
			// buyer logo
			bottom: auto;
			height: 72px;
			left: 0;
			position: absolute;
			top: $marginGeneric * -4.5;
			width: 100%;

			& > svg {
				background-color: $grey_2;
				border-radius: 4px;
				width: 184px;
			}

			@media (min-width: $breakpointContent) {
				& > svg {
					width: 188px;
				}
			}
		}

		& > p {
			// review content
			padding-bottom: $marginGeneric * 5;
			width: 100%;
		}

		h6 {
			// reviewer name

			@include typography.heading6();

			inset: auto 0 $marginGeneric * 3 0;
			margin-bottom: 0;
			position: absolute;
			text-align: center;
		}

		@media (min-width: $breakpointContent) {
			width: $marginGeneric * 42;

			& > p {
				padding-bottom: $marginGeneric * 3;
			}
		}
	}

	.pagination {
		bottom: 0;
		height: $marginGeneric;
		line-height: $marginGeneric;

		> span {
			background-color: $dark_grey_3;
			border-radius: 100%;
			height: $marginGeneric;
			margin: 0 $marginGeneric/2;
			outline: none;
			vertical-align: top;
			width: $marginGeneric;
		}
	}

	&.newTestimonials {
		background-color: color.$off-black;
		
		.testimonials {
			padding-bottom: core.$space * 6;
			
			@include breakpoints.minTablet {
				padding-bottom: core.$space * 10;
			}
		}

		h2 {
			@include typography.heading2();
			max-width: 20ch;
			margin-inline: auto;

			@media (min-width: $breakpointMobile) {
				&.testimonialsTitle {
					@include typography.heading1-base();
				}
			}

			strong {
				font-weight: inherit;
				color: var(--thc-color-primary-yellow);
			}
		}

		.carousel {	
			@media (min-width: $breakpointContent) {
				padding-bottom: 0;
			}
		}
	}
}

.testimonialContainer {
	margin-left: 0;
	margin-right: 0;
	position: relative;
	z-index: 2;

	.carouselVideo {

		figure {

			&:first-child {
				margin-right: 16px;
				
				@media (min-width: $breakpointContent) {
					margin-right: 48px;
				}
				
				@media (min-width: $breakpointContent) {
					margin-right: 48px;
				}
			}

			@media (min-width: $breakpointContent) {
				width: 496px !important;
			}
	
		}
	}

	@media (min-width: $breakpointContent) {
		gap: 0 core.$space7;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;
		margin: 0;
		.title {
			text-align: start;
		}
	}

	.moreQuestions {
		padding-left: core.$space4;
		padding-right: core.$space4;
	}

	.carousel {
		--swiper-pagination-color: #{color.$primary-white};
		--swiper-theme-color: #{color.$primary-white};
	
		background: transparent;
		padding: 24px 0 34px;
		position: relative;
		width: 100%;
	
		@media (min-width: $breakpointContent) {
			width: 1040px;
		}
	
		.carouselItem {
			align-items: center;
			display: flex;
			flex-direction: column;
			gap: core.$space4;
			margin: 0;
			padding: 0 var(--thc-fluid-24-32);
		}
	
		:global {
			.swiper-pagination {
				bottom: 0;
				height: 10px;
				line-height: core.$space2;
				z-index: 1;
			}
	
			.swiper-pagination-bullet {
				background-color: var(--swiper-pagination-color);
				opacity: 0.5;
				transform: scale(0.5);
			}
	
			.swiper-pagination-bullet-active-next,
			.swiper-pagination-bullet-active-prev {
				opacity: 0.75;
				transform: scale(0.75);
			}
	
			.swiper-pagination-bullet-active {
				opacity: 1;
				transform: scale(1);
			}
		}
	}
}

.testimonialVideo {
	--mw-lp-video-poster-border-radius: #{core.$space-16} #{core.$space-16} 0 0;
	--video-poster-aspect-ratio: 1/1;
	--video-poster-image-height: 100%;
	
	display: flex;
	flex-direction: column;

	@include breakpoints.betweenMinMax('tabletMobile', 'tablet') {
		--video-poster-aspect-ratio: 16/9;
	}

	@include breakpoints.minTablet {
		--video-poster-aspect-ratio: 1.2;
	}

	.text {
		background: var(--thc-color-primary-black);
		padding: core.$space-32 core.$space-24;
		border-radius: core.$space-16;
		margin-top: - core.$space-16;
		display: flex;
		flex-direction: column-reverse;
		gap: core.$space-24;
		text-align: left;
		position: relative;
		z-index: 1;

		h3 {
			@include typography.large();
		}

		p {
			@include typography.heading4();

			padding: 0;
		}
	}
}
