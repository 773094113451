@use "sass:math";
@use '~@THC/styles/breakpoints';

@use '~@THC/styles/core';

@use '~@THC/styles/color';

@use '~@THC/styles/typography';

.signupBarWrapper {
	background-color: var(--thc-color-off-black);
	padding: core.$space-24;
}

.signupBar {
	display: grid;
	align-items: end;
	min-height: 470px;
	border-radius: core.$space-16;
	overflow: hidden;
	max-width: 100rem;
	margin-inline: auto;

	> * {
		grid-column: 1 / -1;
		grid-row: 1 / -1;
	}
}

.image {
	height: 100%;

	img,
	picture {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 50% 30%;
	}
}

.content {
	display: grid;
	grid-template-columns: 1fr;
	gap: core.$space-24;
	justify-items: start;
	padding: core.$space-24;
	background: rgba(color.$primary-black, 0.7);
	border-radius: core.$space-16;
	backdrop-filter: blur(core.$space-56);
	border: 1px solid color.$dark-grey;
	order: 1;
	margin: core.$space-24;

	h2 {
		@include typography.reckless;
		@include typography.size('heading3', 'title');
		text-wrap: balance;

		strong {
			font-weight: inherit;
			color: var(--thc-color-primary-yellow);
		}
	}
}

@include breakpoints.minTablet {
	.signupBarWrapper {
		padding: core.$space-48;
	}

	.signupBar {
		position: relative;
	}

	.image {
		position: absolute;
		height: 100%;
		width: 100%;
	}

	.content {
		justify-self: end;
		max-width: 540px;
		padding: core.$space-32 core.$space-48;
		margin: core.$space-80 core.$space-24;
	}
}

@media (min-width: 87.5rem) {
	.content {
		margin-right: clamp(1.5rem, -46.3885rem + 54.7297vw, 11.625rem);
	}
}
