@use "sass:math";
@use '~@THC/styles/typography';

@use '~@THC/styles/core';

@use '~@THC/styles/color';

@use '~@THC/styles/breakpoints';

.sectionWrapper {
	padding-bottom: core.$space-64;
	background-color: color.$primary-black;
	overflow-x: hidden;

	h2 {
		@include typography.heading2();
		@include typography.size('heading2', 'heading1');

		text-align: center;
		margin: 0 auto core.$space-64;
		padding-top: core.$space-48;
		text-wrap: balance;
		max-width: 30ch;

		strong {
			font-weight: inherit;
			color: var(--thc-color-primary-yellow);
		}
	}
}

.section {
	max-width: 82rem;
}

.stepsWrapper {
	display: grid;
	grid-template-columns: 1fr;
	gap: core.$space-48;
}

.step {
	background: var(--thc-color-off-black);
	padding: core.$space-24;
	border-radius: var(--step-section-border-radius, #{core.$space-16});
	display: grid;
	grid-template-columns: repeat(var(--step-columns, 1), 1fr);
	gap: core.$space-16 core.$space-24;
	align-items: center;

	@include breakpoints.minTabletMobile {
		--step-columns: 2;

		&:nth-child(even) .image {
			order: -1;
		}
	}

	@include breakpoints.minTablet {
		--step-section-border-radius: #{core.$space-32};

		padding: core.$space-48;
		gap: core.$space-72;
	}
}

.stepCounter {
	@include typography.heading5;
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 46" preserveAspectRatio="none" fill="none"><path fill="%23FFE14C" d="M86.95 1.7A4 4 0 0 0 83.68 0H4a4 4 0 0 0-4 4v38a4 4 0 0 0 4 4h79.68a4 4 0 0 0 3.27-1.7l13.42-19a4 4 0 0 0 0-4.6l-13.42-19Z"/></svg>');
	background-size: 100% 100%;
	color: var(--thc-color-primary-black);
	padding: core.$space-8 core.$space-24 core.$space-8 core.$space-16;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: core.$space-24;

	h3 {
		@include typography.heading2();
	}

	p {
		color: var(--thc-text-secondary);
	}

	@include breakpoints.minTablet {
		h3 {
			@include typography.heading1-base();
		}

		p {
			@include typography.large-base();
		}
	}
}

.image {
	--step-border-radius: #{core.$space-8};
	
	> * {
		background: var(--thc-color-primary-black);
		border-radius: var(--step-border-radius);
		aspect-ratio: 1 / 1;
	}
	
	picture,
	img {
		aspect-ratio: 1 / 1;
		border-radius: var(--step-border-radius);
		object-fit: contain;
		width: 100%;
		height: 100%;
	}

	@include breakpoints.minTablet {
		--step-border-radius: #{core.$space-24};
	}
}
