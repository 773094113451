@use "sass:math";
@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

.pictureWrapper {
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.1s linear;

	picture {
		display: block;
	}

	&.loaded {
		opacity: 1;
	}

	img {
		max-height: 100%;
		max-width: 100%;
	}
}
