@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

$containerPaddingRegular: $marginGeneric * 5;

@function calculateContainerInnerSize($columns, $columnWidth, $gutter) {
	$totalColumnsWidth: $columnWidth * $columns;
	$totalGuttersWidth: $gutter * ($columns - 1);

	@return $totalColumnsWidth + $totalGuttersWidth;
}

@function calculateContainerOuterSize($columns, $columnWidth, $gutter, $containerPadding: $containerPaddingRegular) {
	$totalContentWidth: calculateContainerInnerSize($columns, $columnWidth, $gutter);
	$totalPaddingWidth: $containerPadding * 2;

	@return $totalContentWidth + $totalPaddingWidth;
}

@mixin container-base($size: 41, $center: true) {
	max-width: $marginGeneric * $size; /* 400 */
	width: 100%;

	@if $center {
		margin-left: auto;
		margin-right: auto;
	}
}

@mixin generateCustomContainerSizes(
	$maxColumns: 5,
	$columnWidth,
	$gutter,
	$containerPadding: $containerPaddingRegular,
	$count: 1
) {
	@if ($count <= $maxColumns) {
		$innerWidth: calculateContainerInnerSize($count, $columnWidth, $gutter);
		$outerWidth: calculateContainerOuterSize($count, $columnWidth, $gutter, $containerPadding);

		// media query is defined by `@outerWidth`, because we want to render more cards as soon as we have available space.
		// it means, that when the screen width match the breakpoint value, container takes 100% of page.
		// if user goes above the breakpoint, container is horizontally centered in page.

		@media (min-width: $outerWidth) {
			max-width: $innerWidth;
		}

		// increment and call loop;
		@include generateCustomContainerSizes($maxColumns, $columnWidth, $gutter, $containerPadding, ($count + 1));
	}
}

@mixin container-20($center: true) {
	@include container-base(20, $center);
}

@mixin container-41($center: true) {
	@include container-base(41, $center);
}

@mixin container-50($center: true) {
	@include container-base(50, $center); /* 400 */
}

@mixin container-64($center: true) {
	@include container-base(64, $center); /* 512 */
}

@mixin container-86($center: true) {
	@include container-base(86, $center); /* 688 */
}

.container-vrm {
	width: $marginGeneric * 14; /* 112 */
}

@mixin generateCustomContainerSizes(
	$maxColumns: 5,
	$columnWidth,
	$gutter,
	$containerPadding: $containerPaddingRegular,
	$count: 1
) {
	@if ($count <= $maxColumns) {
		$innerWidth: calculateContainerInnerSize($count, $columnWidth, $gutter);
		$outerWidth: calculateContainerOuterSize($count, $columnWidth, $gutter, $containerPadding);

		// media query is defined by `$outerWidth`, because we want to render more cards as soon as we have available space.
		// it means, that when the screen width match the breakpoint value, container takes 100% of page.
		// if user goes above the breakpoint, container is horizontally centered in page.

		@media (min-width: $outerWidth) {
			max-width: $innerWidth;
		}

		// increment and call loop;

		@include generateCustomContainerSizes($maxColumns, $columnWidth, $gutter, $containerPadding, ($count + 1));
	}
}
