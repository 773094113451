@use "sass:math";
@use '~@THC/styles/color';

@use '~@THC/styles/core' as core;

@use '~@THC/styles/_breakpoints' as breakpoints;

@use '~@THC/styles/typography';

@use '~@THC/styles/fluid';

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

@import '/utilities/styles/common.scss';

@import '/utilities/styles/containers.scss';

$zorder-hero: 1;
$zorder-uspblock: 10;
$media-hero: 58em;

.heroOuterWrapper {
	position: relative;
	overflow: hidden;
}

.heroWrapper {
	padding: core.$space-24;
	max-width: 80rem;
	margin: 0 auto;
	padding-left: core.$space-24;
	padding-right: core.$space-24;
}

.hero {
	filter: url(#heroFilter);
	position: relative;
	z-index: $zorder-hero;
}

.content {
	--masthead-triangle: #{core.$space-48};

	text-align: center;
	max-width: 45rem;
	margin: 0 auto;
	background: var(--thc-color-off-black);
	border-radius: core.$space-16;
	padding: core.$space-32 core.$space-24 calc(#{core.$space-32} + var(--masthead-triangle));
	display: flex;
	flex-direction: column;
	gap: core.$space-24;
	text-wrap: balance;
	clip-path: polygon(0% 0%, 100% 0, 100% calc(100% - var(--masthead-triangle)), 50% 100%, 0 calc(100% - var(--masthead-triangle)));

	h1 {
		@include typography.reckless();
		@include typography.size('heading1', 'title');

		strong {
			color: var(--thc-color-primary-yellow);
			font-weight: inherit;
		}
	}

	p {
		@include typography.large();
		padding-bottom: 0;
	}

	@include breakpoints.minTabletMobile {
		--masthead-triangle: #{core.$space-80};

		max-width: none;
		margin-left: 0;
		text-align: left;
		padding: core.$space-48 calc(#{core.$space-48} + var(--masthead-triangle)) core.$space-48 core.$space-48;
		clip-path: polygon(0% 0%, calc(100% - var(--masthead-triangle)) 0%, 100% 50%, calc(100% - var(--masthead-triangle)) 100%, 0% 100%);

		h1 {
			text-wrap: auto;
		}
	}

	@include breakpoints.minTablet {
		padding: core.$space-72 calc(#{core.$space-48} + var(--masthead-triangle)) core.$space-72 core.$space-48;

		h1 {
			max-width: 32rem;
		}
	}

	@media (min-width: $media-hero) {
		width: 45rem;
		max-width: 73vw;
	}

	@media (min-width: 87em) {
		margin-left: - core.$space-48;
	}
}

.image {
	--image-width: #{fluid.create(610, 1110, 320, 928)};
	--image-margin: #{fluid.create(-170, -110, 320, 928)};
	--outer-image-width: 25%;

	pointer-events: none;
	display: flex;
	gap: 2%;
	align-items: center;
	width: var(--image-width);
	margin-left: var(--image-margin);
	margin-top: core.$space-32;
	justify-content: center;

	picture,
	img {
		aspect-ratio: 1945 / 985;
	}
	
	img {
		object-fit: contain;
		width: 100%;
		height: auto;
	}

	@media (min-width: $media-hero) {
		--image-width: 70%;
		--image-margin: 0;

		max-width: 1020px;
		position: absolute;
		margin-top: 0;
		top: 50%;
		transform: translateY(-50%);
		left: 525px;
	}

	@media (min-width: 80em) {
		transform: translate(-50%, -50%) translateX(35%);
		left: 50%;
	}
}

.uspBlocks {
	--thc-icon-fill: var(--thc-color-primary-yellow);
	display: grid;
	margin: core.$space-16 auto core.$space-80;
	max-width: 80rem;
	padding-left: core.$space-24;
	padding-right: core.$space-24;
	position: relative;
	text-align: left;
	z-index: $zorder-uspblock;
	gap: core.$space-48 core.$space-24;
	grid-template-columns: repeat(var(--usp-columns, 1), 1fr);

	li {
		width: 100%;
		background: var(--thc-color-off-black);
		padding: core.$space-24;
		border-radius: core.$space-16;
		display: flex;
		flex-direction: column;
		gap: core.$space-8;
		align-items: flex-start;

		h3 {
			@include typography.heading3();
			margin-top: core.$space-8;
		}

		p {
			padding-bottom: 0;
			color: var(--thc-text-secondary);
		}
	}

	@include breakpoints.minMobile {
		--usp-columns: 2;
	}

	@include breakpoints.minTablet {
		--usp-columns: 4;
		margin-top: core.$space-48;
	}
}
